import { GMap } from 'primereact/gmap';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadGoogleMaps, removeGoogleMaps } from '../../rides/pages/GoogleMaps';

export default function DriversLocations() {
    const getDashboardDataReducer = useSelector((state) => state.getDashboardData);
    const { data, } = getDashboardDataReducer;
    const google = window.google;
    const [googleMapsReady, setGoogleMapsReady] = useState(false);


    var mapRef = useRef(null);

    useEffect(() => {
        loadGoogleMaps(() => {
            setGoogleMapsReady(true);

        });

        return () => {
            removeGoogleMaps();
        }
        // new google.maps.Marker({position: {lat: 33.6844, lng: 73.0479}, title:"Konyaalti",    icon:{
        // url:'/assets/cab.png',
        // fillColor: '#00FF00',
        // fillOpacity: 1.0,
        // strokeColor: '#000000',
        // strokeWeight: 1.5,
        // scale: 2,
        // anchor: new google.maps.Point(12, 24),
        // },})
    }, [])

    const [overlays, setOverlays] = useState([]);
    const onMarkerClick = (evt) => {
    };


    const onMapReady = (event) => {

        let markers = [];
        data.startedRoutesDriversLocations.forEach((item) => {
            markers.push(addMarker(item.lastLocation.latitude, item.lastLocation.longitude, item._id));

        })

        setOverlays(
            [

                ...markers
                // new google.maps.Marker({position: {lat: 33.6844, lng: 73.0479}, title:"Konyaalti",    icon:{
                // url:'/assets/cab.png',

                // fillColor: '#00FF00',
                // fillOpacity: 1.0,
                // strokeColor: '#000000',
                // strokeWeight: 1.5,
                // scale: 2,
                // anchor: new google.maps.Point(12, 24),
                // },}),
                // new google.maps.Marker({position: {lat: 36.883707, lng: 30.689216}, title:"Ataturk Park"}),
                // new google.maps.Marker({position: {lat: 36.885233, lng: 30.702323}, title:"Oldtown"}),
                // new google.maps.Polygon({paths: [
                //     {lat: 36.9177, lng: 30.7854},{lat: 36.8851, lng: 30.7802},{lat: 36.8829, lng: 30.8111},{lat: 36.9177, lng: 30.8159}
                // ], strokeOpacity: 0.5, strokeWeight: 1, fillColor: '#1976D2', fillOpacity: 0.35
                // }),
                // new google.maps.Circle({center: {lat: 36.90707, lng: 30.56533}, fillColor: '#1976D2', fillOpacity: 0.35, strokeWeight: 1, radius: 1500}),
                // new google.maps.Polyline({path: [{lat: 36.86149, lng: 30.63743},{lat: 36.86341, lng: 30.72463}], geodesic: true, strokeColor: '#FF0000', strokeOpacity: 0.5, strokeWeight: 2})
            ]
        );

    }

    const addMarker = (lat, lng, id) => {
        let newMarker;
        newMarker = new google.maps.Marker({
            position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            },
            onClick: onMarkerClick,
            id: id,



            draggable: false,
            icon: {
                url: '/assets/cab.png',
                // fillColor: '#00FF00',
                fillOpacity: 1.0,
                // strokeColor: '#000000',
                strokeWeight: 1,
                scale: 1,
                anchor: new google.maps.Point(12, 24),
            },


        });

        // setOverlays((prev) => [...prev, newMarker]);
        // Create an info window
        const infoWindow = new window.google.maps.InfoWindow({

            content: ""
            //         `
            //    <div>
            //    <p><b>Owner</b></p>
            // <p><b>Name: </b>${ride?.userId?.firstName.charAt(0).toUpperCase()+ride?.userId?.firstName.slice(1)} ${ride?.userId?.lastName.charAt(0).toUpperCase()+ride?.userId?.lastName.slice(1)}</p>
            // <p><b>End Location: </b>${placeName}</p>
            //  </div>
            //    `
            ,

        });

        const func = () => {
            return (
                <div>
                </div>
            )
        }

        // Open the info window on marker hover
        newMarker.addListener('mouseover', () => {
            infoWindow.open(func, newMarker);
        });

        // Close the info window on marker mouseout
        newMarker.addListener('mouseout', () => {
            infoWindow.close();
        });
        return newMarker;
    }
    const onMapClick = (event) => {

        // setDialogVisible(true);
        // setSelectedPosition(event.latLng)
    }


    const history = useHistory()
    const onOverlayClick = (event) => {
        let isMarker = event.overlay.getTitle !== undefined;
        if (isMarker) {

            history.push(`/api/rides/active/driver/${event?.overlay?.id}`)

        }


    }


    const handleDragEnd = (event) => {
    }

    const defaultProps = {
        center: {
            lat: 33.6844,
            lng: 73.0479
        },
        zoom: 10
    };
    return (
        <div>

            {
                googleMapsReady && (
                    <div className="card">
                        <h4>Active ride owner location</h4>
                        <GMap ref={(ref) => { mapRef = ref }}
                            overlays={overlays}
                            options={defaultProps}
                            style={{ width: '100%', minHeight: '500px' }} onMapReady={onMapReady}
                            onMapClick={onMapClick} onOverlayClick={onOverlayClick} onOverlayDragEnd={handleDragEnd} />
                    </div>
                )
            }
        </div>
    )
}
