import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import AddEditFaqs from "../pages/add_edit_faqs";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { deleteFaqs } from "../../../../../redux/slices/faqs_slice";
import { useDispatch } from "react-redux";


export default function FaqItem({ faq, category }) {
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const [editFaq, setEditFaq] = useState(null);
    const [showAddEditFaqDialog, setShowAddEditFaqDialog] = useState(false);

    const accept = () => {
        dispatch(deleteFaqs(faq._id))
    }

    const reject = () => {
    }
    const deleteFAQ = () => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    const dialogFuncMap = {
        showAddEditFaqDialog: setShowAddEditFaqDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };


    return (
        <div>
            <Dialog header="Update FAQs" visible={showAddEditFaqDialog} onHide={() => onHide("showAddEditFaqDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <AddEditFaqs editData={editFaq} category={category} onHide={() => onHide("showAddEditFaqDialog")} />
            </Dialog>
            <div
                className="card"
                style={{ background: "lightGrey" }}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                <ConfirmDialog />
                <Toast ref={toast} />
                <div className="">
                    <div className="flex justify-content-end flex-end">
                        <i className="pi pi-chevron-down mr-2"></i>
                    </div>

                    <div className="grid">
                        <div className="col-10">{faq.question}</div>
                        <div className="col-2">
                            <Button icon="pi pi-pencil" onClick={() => {
                                onClick('showAddEditFaqDialog')
                                setEditFaq(faq);
                            }} className="p-button-rounded p-button-text p-0" aria-label="Submit" style={{ color: "blue", cursor: "pointer" }} />
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-text" aria-label="Submit" style={{ color: "red", cursor: "pointer" }}
                                onClick={deleteFAQ}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {expanded && <div className="card">    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>}
        </div>
    );
}
