import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import EditUserDialog from "../../users/components/edit_passenger_dialog";
import DocumentsDialog from "../components/documentsDialog";
import { useHistory } from "react-router-dom";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import UserProfileDialog from "../../users/components/user_profile_dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import VerificationDialog from "../components/licence_verification_dialog";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";
import { getDriversList } from "../../../../../redux/slices/drivers_slice";
import moment from "moment";

export default function DriversScreen(props) {
    const permissions = props?.permissions;
    //redux
    const driversListReducer = useSelector((state) => state.driversList);
    const { drivers, loading, count = 0, updateSuccess } = driversListReducer;
    const dispatch = useDispatch();

    //hooks
    const [selectedCategory] = useState("all");
    const [showEditDialog, setEditDialog] = useState(false);
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [showDocumentsDialog, setDocumentsDialog] = useState(false);
    const [showLicenceDialog, setLicenceDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");
    const [editUser, setEditUser] = useState(null);
    const history = useHistory();
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;

        setGlobalFilterValue(value);
    };

    // dialog
    const dialogFuncMap = {
        showEditDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        showDocumentsDialog: setDocumentsDialog,
        showLicenceDialog: setLicenceDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    //coponents
    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Owners"}</div>;
    };

    const activeStatusBody = (rowData) => {
        return <div className={rowData.active.status ? "bg_green" : "bg_red"}>{rowData.active.status ? "Active" : "Inactive"}</div>;
    };
    const actionTemplate = (rowData) => {
        // const rating = rowData?.totalRating / rowData?.totalRatingCount;
        // let ratingFixed;
        // if (rating) {
        //     ratingFixed = rating.toFixed(2)
        // } else if(!rating) {
        //     return "";
        // }
        // console.log(ratingFixed)
        return (
            <div className="Edit_Icon">
                <>
                    {permissions?.view && (
                        <Button
                            tooltip="View Ratings"
                            onClick={() => {
                                history.push(`/api/user/drivers/ratings/list/${rowData?._id}/1`);
                            }}
                            icon="pi pi-star-fill"
                            // badge={ratingFixed}
                            // badgeClassName="p-badge-danger"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-warning m-2 p-button-rounded"
                        />
                    )}
                    {permissions?.view && (
                        <Button
                            tooltip="View Vehicles List"
                            icon="pi pi-car"
                            tooltipOptions={{ position: "top" }}
                            className="edit m-2 p-button-rounded"
                            onClick={() => {
                                history.push(`/api/user/drivers/vehicles/list/${rowData?._id}/1`);
                            }}
                        />
                    )}
                    {permissions?.edit && (
                        <Button
                            tooltip="Status"
                            icon="pi pi-pencil"
                            tooltipOptions={{ position: "top" }}
                            className="edit m-2 p-button-rounded"
                            onClick={() => {
                                setEditUser(rowData);
                                onClick("showEditDialog");
                            }}
                        />
                    )}
                    {permissions?.view && (
                        <Button
                            tooltip="Document Verification"
                            icon="pi pi-image"
                            tooltipOptions={{ position: "top" }}
                            className="edit m-2 p-button-rounded"
                            onClick={() => {
                                setEditUser(rowData);
                                onClick("showDocumentsDialog");
                            }}
                        />
                    )}
                    {/* <Button
                    tooltip="Vehicles"
                    icon="pi pi-car"
                    tooltipOptions={{ position: "top" }}
                    className="edit m-2 p-button-rounded"
                    onClick={() => {
                        setEditUser(rowData);
                        onClick("showUserVehicleDialog");
                        // history.push(`/api/user/drivers/vehicles/list/${rowData._id}`);
                    }}
                /> */}

                    {permissions?.view && (
                        <Button
                            tooltip="Rides"
                            icon="pi pi-car"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-success m-2 p-button-rounded"
                            onClick={() => {
                                history.push(`/api/user/drivers/rides/list/${rowData?._id}/1`);
                            }}
                        />
                    )}

                    {permissions?.view && (
                        <Avatar
                            icon="pi pi-user"
                            className="m-2 p-button-rounded"
                            size="large"
                            shape="circle"
                            tooltipOptions={{ position: "top" }}
                            tooltip="View Profile"
                            onClick={() => {
                                setEditUser(rowData);
                                onClick("showProfileDialog");
                            }}
                        />
                    )}
                </>
            </div>
        );
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;

        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const genderTemp = (rowData) => {
        const gender = rowData.gender;

        return <div>{gender.charAt(0).toUpperCase()}</div>;
    };
    const signupAsTemp = (rowData) => {
        if (rowData.signupAs == 1) {
            return "Owner";
        } else if (rowData.signupAs == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const updatedAtTemplate = (rowData) => {
        return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    useEffect(() => {
        dispatch(getDriversList({ status: selectedCategory, pageNumber: 0 }));
    }, [selectedCategory, dispatch]);
    useEffect(() => {
        if (updateSuccess) dispatch(getDriversList({ status: selectedCategory, pageNumber: 0 }));
    }, [updateSuccess]);
    useEffect(() => {
        if (globalFilterValue) {
            setPageNumber(0);
            setFirst(0);
        }
        dispatch(getDriversList({ status: selectedCategory, pageNumber, text: globalFilterValue }));
    }, [globalFilterValue]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        dispatch(getDriversList({ status: selectedCategory, pageNumber: event.first / event.rows, text: globalFilterValue }));
    };
    return (
        <>
            <Dialog header="Update Status" visible={showEditDialog} onHide={() => onHide("showEditDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "30vw" }}>
                <EditUserDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showEditDialog")} />
            </Dialog>

            <Dialog header="Document verifications" visible={showDocumentsDialog} onHide={() => onHide("showDocumentsDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "70vw" }}>
                <div>{editUser != null && <DocumentsDialog permissions={permissions} onHide={() => onHide("showDocumentsDialog")} userId={editUser._id} user={editUser} updateSuccess={updateSuccess} />}</div>
            </Dialog>

            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} editUserData={editUser} userType="1" />}</div>
            </Dialog>

            <Dialog header="License Verification" visible={showLicenceDialog} onHide={() => onHide("showLicenceDialog")} breakpoints={{ "960px": "75vw", "640px": "90vw" }} style={{ width: "70vw" }}>
                <VerificationDialog permissions={permissions} editUser={editUser} onHide={() => onHide("showLicenceDialog")} />
            </Dialog>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-7">
                        <CustomPageHeader title={"Owners"} />
                    </div>
                    <div className="mb-9 text-right col-12 md:col-5">
                        <span className="p-input-icon-right">
                            <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            {/* <Dropdown className="ml-3" value={selectedCategory} options={userList} optionLabel="status" optionValue="value" onChange={(e) => setSelectedCategory(e.value)} placeholder="Select User" /> */}
                        </span>
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={drivers} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile"]} emptyMessage="No record found.">
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "30px", height: "30px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data?.profileImage}` : defaultImage} onError={() => setDefaultImage("app/black-watch.jpg")} />
                                </div>
                            );
                        }}
                    />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={firstNameTemp} header="First Name" />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={lastNameTemp} header="Last Name" />
                    <Column style={{ minWidth: "" }} field="cnic" header="CNIC" />
                    <Column style={{ minWidth: "" }} field="mobile" header="Mobile" />
                    <Column style={{ minWidth: "" }} body={userTypeTemp} header="Type" />
                    <Column style={{ minWidth: "" }} body={genderTemp} header="Gender" />
                    <Column style={{ minWidth: "" }} body={signupAsTemp} header="Signup As" />
                    <Column style={{ minWidth: "" }} field="loginDevice" header="Log In Device" />
                    <Column style={{ minWidth: "" }} field="signupDevice" header="Signup Device" />
                    <Column style={{ minWidth: "8rem" }} body={createdAtTemplate} header="Created At" />
                    <Column style={{ minWidth: "8rem" }} body={updatedAtTemplate} header="Updated At" />
                    <Column body={activeStatusBody} header="Status" />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div>
        </>
    );
}
