import { Button } from "primereact/button";
import React, { useRef, useState } from "react";
import FaqItem from "./faq_item";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export default function CategoryItem({ title, faqs, categoryOnClick, onSelectedCategory, cat, deleteOnClick }) {
    const toast = useRef(null);
    const [expanded, setExpanded] = useState(false);

    const accept = () => {
        deleteOnClick();
        toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Category contains FAQs and cannot be deleted!', life: 3000 });

    }
    const reject = () => {
    }
    const deleteCat = () => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };
    return (
        <div>
            <div>
                <div className="flex flex-row justify-content-between">
                    <h4>{title}</h4>
                    <ConfirmDialog />
                    <Toast ref={toast} />
                    <div className="flex flex-row ">
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" aria-label="Submit" onClick={() => {
                            categoryOnClick();
                            onSelectedCategory();
                        }} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-text" aria-label="Submit" style={{ color: "red" }}
                            onClick={deleteCat}
                        />

                        <i onClick={() => {
                            setExpanded(!expanded);
                        }} className={`${expanded ? "pi pi-chevron-down" : "pi pi-chevron-up"}`}></i>
                    </div>
                </div>
            </div>
            {expanded && (
                <div className="">
                    {faqs.map((item, index) => {
                        return (
                            <div key={item._id}>
                                <FaqItem className="card" category={cat} faq={item} />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
