import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import CustomLoading from "../../../components/custom_loading";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { getreferrrerList } from "../../../../../redux/slices/referrer_slice";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Avatar } from "primereact/avatar";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import UserAccountProfileDialog from "../../accounts/components/user_account_profile";

export default function ReferrerScreen({ permissions }) {
    const referrerReducer = useSelector((state) => state.referrer);
    const { data: list, loading } = referrerReducer;
    const [showReferrerProfileDialog, setReferrerProfileDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const dispatch = useDispatch();
    const [editReferrerUser, setEditReferrerUser] = useState(null);
    const [defaultImage] = useState("app/black-watch.jpg");

    //dialogs
    const dialogFuncMap = {
        showReferrerProfileDialog: setReferrerProfileDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const signupAsTemp = (rowData) => {
        if (rowData?.signupAs == 1) {
            return "Owner";
        } else if (rowData?.signupAs == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    const currentRoleTemp = (rowData) => {
        if (rowData.currentRole == 1) {
            return "Owner";
        } else if (rowData.currentRole == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    const referrerUserTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            setEditReferrerUser(rowData);
                            onClick("showReferrerProfileDialog");
                        }}
                    />
                )}
            </div>
        );
    };
    const rowExpansionTemplate = (rowData) => {
        return (
            <>
                <h5>Users</h5>
                <DataTable showGridlines={true} value={rowData.users}>
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data.profileImage}` ? `${appUrl.fileBaseUrl}${data.profileImage}` : defaultImage} />
                                </div>
                            );
                        }}
                    />
                    <Column style={{ padding: "2rem 1rem" }} field="firstName" header="First Name" />
                    <Column style={{ padding: "2rem 1rem" }} field="lastName" header="Last Name " />
                    <Column style={{ padding: "2rem 1rem" }} body={createdAtTemplate} header="Signup Time " />
                    <Column style={{ padding: "2rem 1rem" }} field="signupDevice" header="Signup Device " />
                    <Column body={signupAsTemp} header="Signup As" />
                    <Column body={currentRoleTemp} header="Current Role" />
                </DataTable>
            </>
        );
    };
    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };
    useEffect(() => {
        dispatch(getreferrrerList());
    }, []);

    return (
        <>
            {loading === true || list === undefined ? (
                <CustomLoading />
            ) : (
                <>
                    <Dialog header="Profile" position="center" className="text-center" visible={showReferrerProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showReferrerProfileDialog")}>
                        <div>{editReferrerUser != null && <UserAccountProfileDialog permissions={permissions} userId={editReferrerUser.referBy.referById} user={editReferrerUser} userType="1" />}</div>
                    </Dialog>

                    <div className="card">
                        <div className="grid pb-3">
                            <div className="col-12 md:col-6">
                                <h4>Referrer</h4>
                            </div>
                        </div>
                        <DataTable
                            filter
                            expandedRows={expandedRows}
                            rows={5}
                            paginator
                            value={list}
                            responsiveLayout="scroll"
                            onRowToggle={(e) => {
                                setExpandedRows(e.data);
                            }}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column expander={allowExpansion} style={{ width: "3em" }} />

                            <Column
                                header="Image"
                                body={(data) => {
                                    return (
                                        <div>
                                            <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data.referBy.profileImage}` ? `${appUrl.fileBaseUrl}${data.referBy.profileImage}` : defaultImage} />
                                        </div>
                                    );
                                }}
                            />
                            <Column style={{ padding: "2rem 1rem" }} field="referBy.firstName" header="First Name"></Column>
                            <Column style={{ padding: "2rem 1rem" }} field="referBy.lastName" header="Last Name"></Column>
                            <Column style={{ padding: "2rem 1rem" }} field="referBy.inviteCode" header="Invite Code"></Column>
                            <Column style={{ padding: "2rem 1rem" }} field="total" header="Total Referrer"></Column>
                            <Column header="Action" body={referrerUserTemplate}></Column>
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
}
