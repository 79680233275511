import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import { logoutUser } from './redux/slices/admin_user_slice';
//import {  LOGOUT_USER } from './redux/constants/admin_user_constants';



export const AppTopbar = (props) => {

    const dispatch = useDispatch();
    const accept = () => {
    //dispatch({type:LOGOUT_USER})
    dispatch(logoutUser())
    }

    const reject = () => {
    }


    const signoutConfirm = () => {
        confirmDialog({
            message: 'Do you want to logout?',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-success p-button-rounded p-button-sm custom___width',
            accept,
            reject
        });
    };


    return (
        <div className="layout-topbar">

            <ConfirmDialog />

            <Link to="/" className="layout-topbar-logo">
                <img src='app/logo.png' alt="logo" />
                {/* <span>Rahper</span> */}
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>

                <li>
                    <button className="p-link layout-topbar-button" onClick={() => {
                        signoutConfirm();
                    }}>
                        <i className="pi pi-power-off" />
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
