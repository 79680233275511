import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCorporateCode, editCorporateCode, getCorporateCodesList, resetAddEditCorporateCode } from "../../../../../redux/slices/corporate_code_slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInputField from "../../../components/custom_input_filed";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
export default function AddCorporateCodeDialog({ onHide, editCode, permissions }) {
    //redux
    const corporateCodeReducer = useSelector((state) => state.corporateCode);
    const { addLoading, addError, editLoading, editError, addSuccess, editSuccess } = corporateCodeReducer;

    const dispatch = useDispatch();
    const statusList = [
        {
            code: true,
            name: "Active",
        },
        {
            code: false,
            name: "InActive",
        },
    ];
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        code: Yup.string().required("This field is required.").min(11, "Please enter valid code xxxx-xxxxxx").max(11, "Please enter valid code xxxx-xxxxxx"),
        address: Yup.string().required("This field is required."),
        fee: Yup.number().typeError("Please enter a valid number").min(-100, "Number must be greater than or equal to -100").max(100, "Number must be less than or equal to 100").required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            code: "",
            address: "",
            fee: "",
        },

        onSubmit: async (data) => {
            if (editCode == null) {
                dispatch(addCorporateCode(data));
            } else {
                data["corporateId"] = editCode._id;
                dispatch(editCorporateCode(data));
            }
        },
    });

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Corporate Code successfully added");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Corporate Code successfully updated");
                onHide();
                dispatch(getCorporateCodesList());
            } else {
                toast.error(editError);
            }
        }
        return () => {
            dispatch(resetAddEditCorporateCode());
        };
    }, [addSuccess, addError, editSuccess, editError]);

    useEffect(() => {
        if (editCode !== null) {
            loadInitialValues();
        }
    }, []);

    const loadInitialValues = () => {
        formik.setFieldValue("name", editCode.name);
        formik.setFieldValue("address", editCode.address);
        formik.setFieldValue("code", editCode.code);
        formik.setFieldValue("fee", editCode.fee);
        formik.setFieldValue("active", editCode["active"]);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="grid">
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="name" formik={formik} type="text" disabled={editCode !== null} />
                </div>
                <div className="p-fluid col-12 md:col-6">{editCode !== null ? <CustomInputField iden="code" formik={formik} placeHolder="xxxx-xxxxxx" type="text" disabled={true} /> : <CustomInputField iden="code" formik={formik} mask="****-******" placeHolder="xxxx-xxxxxx" type="text" />} </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="address" formik={formik} type="text" disabled={editCode !== null} />
                </div>
                <div className="p-fluid col-12 md:col-6">
                    <CustomInputField iden="fee" formik={formik} type="number" />
                </div>
                {editCode != null && (
                    <div className="p-fluid col-12 md:col-6">
                        <div className="field">
                            <CustomInputField iden={"active"} formik={formik} type="dropdown" optionLabel="name" optionValue="code" options={statusList} />
                        </div>
                    </div>
                )}
                <div className="p-fluid col-12">
                    <div className="button_class-modal">{permissions?.edit && editCode !== null && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                    <div className="button_class-modal">{permissions?.add && !editCode && <Button loading={editCode == null ? addLoading : editLoading} type="submit" label={editCode === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}</div>
                </div>
            </div>
        </form>
    );
}
