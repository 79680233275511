import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useDispatch, useSelector } from "react-redux";
import CustomLoading from "../../../components/custom_loading";
import DriversLocations from "../components/drivers_locations";
import CustomPageHeader from "../../../components/custom_page_header";
import { getDashboardStat } from "../../../../../redux/slices/dashboard_slice";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
const Dashboard = () => {
    const history = useHistory();
    const [lineData, setLineData] = useState();
    const [barData, setBarData] = useState();
    const [lineOptions, setLineOptions] = useState(null);
    const [startDate, setStartDate] = useState(new Date().getFullYear().toString() + "-" + new Date().getMonth().toString().padStart(2, 0) + "-" + new Date().getDate().toString().padStart(2, 0));
    const [endDate, setEndDate] = useState(new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString().padStart(2, 0) + "-" + (new Date().getDate() + 1).toString().padStart(2, 0));
    const [passengerPieChartData, setPassengerPieChartData] = useState();
    const [driverPieChartData, setDriverPieChartData] = useState();

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    };
    const dispatch = useDispatch();
    const getDashboardStatReducer = useSelector((state) => state.getDashboardStat);
    const { data, loading, error } = getDashboardStatReducer;
    useEffect(() => {
        dispatch(getDashboardStat({ startDate, endDate }));
    }, [startDate, endDate]);
    useEffect(() => {
        if (data !== undefined && loading === false) {
            let driverMonths = data?.routeStat?.lineChartData.map((item) => item._id);
            let driverCounts = data?.routeStat?.lineChartData.map((item) => item.count);

            let passengerCounts = data?.scheduleStat?.lineChartData.map((item) => item.count);
            let chartData = {
                labels: driverMonths,
                datasets: [
                    {
                        label: "Owners schedules",
                        data: driverCounts,
                        fill: false,
                        backgroundColor: "#2f4860",
                        borderColor: "#2f4860",
                        tension: 0.4,
                    },
                    {
                        label: "Passenger schedules",
                        data: passengerCounts,
                        fill: false,
                        backgroundColor: "#00bb7e",
                        borderColor: "#00bb7e",
                        tension: 0.4,
                    },
                ],
            };
            setLineData(chartData);
            let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            let maleMonths = data?.userStat.genderBarChart.filter((item) => item._id.gender === "male").map((item) => `${monthNames[item._id.month - 1]} ${item.year}`);
            let maleCounts = data?.userStat.genderBarChart.filter((item) => item._id.gender === "male").map((item) => item.count);
            let femaleCounts = data?.userStat.genderBarChart.filter((item) => item._id.gender === "female").map((item) => item.count);

            const barChartData = {
                labels: maleMonths,
                datasets: [
                    {
                        label: "Males",
                        backgroundColor: "#2f4860",
                        data: maleCounts,
                    },
                    {
                        label: "Females",
                        backgroundColor: "#00bb7e",
                        data: femaleCounts,
                    },
                ],
            };
            setBarData(barChartData);
            let passengerPieChartData = {
                labels: ["Accepted", "Rejected", "Cancelled"],
                datasets: [
                    {
                        data: [data?.pieChartData?.passengerAcceptedRequests?.total, data?.pieChartData?.passengerRejectedRequests?.total, data?.pieChartData?.passengerCancelledRequests?.total],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            };
            setPassengerPieChartData(passengerPieChartData);
            let driverPieChartData = {
                labels: ["Accepted", "Rejected", "Cancelled"],
                datasets: [
                    {
                        data: [data?.pieChartData?.driverAcceptedRequests?.total, data?.pieChartData?.driverRejectedRequests?.total, data?.pieChartData?.driverCancelledRequests?.total],
                        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                    },
                ],
            };
            setDriverPieChartData(driverPieChartData);
        }
    }, [data]);

    useEffect(() => {
        applyLightTheme();
    }, []);

    return (
        <>
            {loading === undefined || data === undefined ? (
                <CustomLoading />
            ) : (
                <>
                    <div className="grid">
                        <div className="col-6">
                            <CustomPageHeader title={"Dashboard"} />
                        </div>
                        <div className="col-6">
                            <div className="both__date">
                                <InputText value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="date__input" />

                                <InputText value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="date__input" />
                            </div>
                        </div>

                        {data && (
                            <>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Total Users</span>
                                                <div className="text-900 font-medium text-xl">{data.userStat.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-users text-blue-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Passengers </span>
                                                <span className="text-green-500 font-medium">{data.userStat?.driver} </span>
                                            </div>

                                            <div>
                                                <span className="text-500">Owners</span>
                                                <span className="text-green-500 font-medium">{data.userStat?.passenger} </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data.userStat?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data.userStat?.female}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Blocked User</span>
                                                <div className="text-900 font-medium text-xl">{data.userStat.blocked}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-user-minus  text-orange-500 text-xl" />
                                            </div>
                                        </div>
                                        <span className="text-500">Blocked User</span>
                                        <span className="text-green-500 font-medium">{data?.userStat?.blocked}</span>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Passenger active rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.active?.total}</div>
                                            </div>

                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.active?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Passenger inactive rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.pending?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.pending?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Passenger cancelled rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.cancelled?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.cancelled?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Passenger completed rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.scheduleStat?.countByStatus?.completed?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.scheduleStat?.countByStatus?.completed?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Owner active rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.routeStat?.countByStatus?.started?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.started?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.started?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.started?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.started?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Owner inactive rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.routeStat?.countByStatus?.active?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.active?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.active?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.active?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.active?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Owner cancelled rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.routeStat?.countByStatus?.cancelled?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.cancelled?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.cancelled?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.cancelled?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.cancelled?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Owner completed rides</span>
                                                <div className="text-900 font-medium text-xl">{data?.routeStat?.countByStatus?.completed?.total}</div>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-car text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.completed?.male}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.completed?.female}</span>
                                            </div>
                                        </div>
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="text-500">Today Male</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.completed?.todayMale}</span>
                                            </div>

                                            <div>
                                                <span className="text-500">Today Female</span>
                                                <span className="text-green-500 font-medium">{data?.routeStat?.countByStatus?.completed?.todayFemale}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Total Amount Collected</span>
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-money-bill text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        <span className="text-500">Today</span>
                                    </div>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-3">
                                    <div className="card mb-0 dashboard_card">
                                        <div className="flex justify-content-between mb-3">
                                            <div>
                                                <span className="block text-500 font-medium mb-3">Pending Amount</span>
                                                {/* <div className="text-900 font-medium text-xl">{data.routeStat.countByStatus.completed}</div> */}
                                            </div>
                                            <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                                <i className="pi pi-money-bill text-cyan-500 text-xl" />
                                            </div>
                                        </div>
                                        {/* <span className="text-green-500 font-medium">{data.routeStat.countByStatus.completedToday} </span> */}
                                        <span className="text-500">Today</span>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="col-12 lg:col-6">
                            {lineData !== undefined && (
                                <div className="card">
                                    <h5>Active Schedules</h5>
                                    <Chart type="line" data={lineData} options={lineOptions} />
                                </div>
                            )}
                            <div className="card flex flex-column align-items-center">
                                <h5>Passenger to owners requests</h5>
                                <Chart type="pie" data={passengerPieChartData} options={null} style={{ width: "50%" }} />
                            </div>
                        </div>
                        <div className="col-12 lg:col-6">
                            <div className="card">
                                <h5>Active Users</h5>
                                <Chart type="bar" data={barData} options={null} />
                            </div>
                            <div className="card flex flex-column align-items-center">
                                <h5>Owners to passenger requests</h5>
                                <Chart type="pie" data={driverPieChartData} options={null} style={{ width: "50%" }} />
                            </div>
                        </div>
                        <div className="col-12 lg:col-12 xl:col-12">
                            <DriversLocations />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
