import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditUserDialog from "../components/edit_passenger_dialog";
//import { getUsersList } from "../../../../../redux/actions/users_actions";
import { useHistory } from "react-router-dom";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Avatar } from "primereact/avatar";
import UserProfileDialog from "../components/user_profile_dialog";
import { InputText } from "primereact/inputtext";
import CustomPageHeader from "../../../components/custom_page_header";
import { getUsersList } from "../../../../../redux/slices/users_slice";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import ProfileEditDialog from "../components/profile_edit_dialog";

export default function UsersScreen(props) {
    const permissions = props?.permissions;
    //redux
    const getUsersListReducer = useSelector((state) => state.usersList);
    const { users, loading, count } = getUsersListReducer;
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");
    const [pageNumber, setPageNumber] = useState(0);
    //hooks
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(null);
    const [editDialog, setEditDialog] = useState(false);
    const [profileEditDialog, setprofileEditDialog] = useState(false);
    console.log(profileEditDialog)
    const history = useHistory();
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
        // let _filters = { ...filters };
        // _filters["global"].value = value;
        // setFilters(_filters);
        // setGlobalFilterValue(value);
    };

    //dialogs
    const dialogFuncMap = {
        editDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
        profileEditDialog: setprofileEditDialog
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    //use effects
    useEffect(() => {
        if (globalFilterValue) {
            setPageNumber(0);
            setFirst(0);
        }
        dispatch(getUsersList({ pageNumber, text: globalFilterValue }));
    }, [globalFilterValue]);
    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        dispatch(getUsersList({ pageNumber: event.first / event.rows, text: globalFilterValue }));
    };
    //methods
    //table body
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Ratings"
                        onClick={() => {
                            history.push(`/api/user/passengers/ratings/list/${rowData?._id}/2`);
                        }}
                        icon="pi pi-star-fill"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-warning m-2 p-button-rounded"
                    />
                )}
                {permissions?.edit && (
                    <Button
                        tooltip="View Status"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("editDialog");
                        }}
                        className="edit m-2 p-button-rounded"
                    />
                )}

                {permissions?.view && (
                    <Button
                        tooltip="Rides"
                        icon="pi pi-car"
                        tooltipOptions={{ position: "top" }}
                        className="p-button-success m-2 p-button-rounded"
                        onClick={() => {
                            history.push(`/api/user/passengers/rides/list/${rowData?._id}/2`);
                        }}
                    />
                )}
                {permissions?.view && (
                    <Avatar
                        icon="pi pi-user"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="View Profile"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("showProfileDialog");
                        }}
                    />
                )}
                {permissions?.view && (
                    <Avatar
                        icon="pi pi-user-edit"
                        className="m-2 p-button-rounded"
                        size="large"
                        shape="circle"
                        tooltipOptions={{ position: "top" }}
                        tooltip="Edit Profile"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("profileEditDialog");
                        }}
                    />
                )}
            </div>
        );
    };
    const statusBody = (rowData) => {
        // return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
        return <div className={rowData.active.status ? "bg_green" : "bg_red"}>{rowData.active.status ? "Active" : "Inactive"}</div>;
    };

    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Driver"}</div>;
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const genderTemp = (rowData) => {
        const gender = rowData.gender;

        return <div>{gender.charAt(0).toUpperCase()}</div>;
    };
    const signupAsTemp = (rowData) => {
        if (rowData.signupAs == 1) {
            return "Owner";
        } else if (rowData.signupAs == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    const createdAtTemplate = (rowData) => {
        return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    };

    const updatedAtTemplate = (rowData) => {
        return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    };
    return (
        <>
            <Dialog header="Update Status" visible={editDialog} onHide={() => onHide("editDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "30vw" }}>
                <EditUserDialog permissions={permissions} editUser={editUser} onHide={() => onHide("editDialog")} />
            </Dialog>

            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserProfileDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <Dialog header="Edit Profile" position="center" className="text-center" visible={profileEditDialog } style={{ width: "30vw", height: "" }} onHide={() => onHide("profileEditDialog")}>
            <div>{editUser != null && <ProfileEditDialog permissions={permissions} userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-7">
                        <CustomPageHeader title={"Passengers"} />
                    </div>
                    <div className="mb-9 text-right col-12 md:col-5">
                        <span className="p-input-icon-right">
                            <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                    </div>
                </div>
                <DataTable loading={loading} responsiveLayout="scroll" value={users} key="_id" globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]} emptyMessage="No record found.">
                    {/* <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} header="Sr" /> */}
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                // <div>
                                //     <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data.profileImage}`?`${appUrl.fileBaseUrl}${data.profileImage}`:defaultImage} />
                                // </div>
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "30px", height: "30px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data.profileImage}` : defaultImage} onError={() => setDefaultImage("app/black-watch.jpg")} />
                                </div>
                            );
                        }}
                    />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={firstNameTemp} header="First Name" />
                    <Column style={{ maxWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} body={lastNameTemp} header="Last Name" />
                    <Column style={{ minWidth: "" }} field="cnic" header="CNIC" />
                    <Column style={{ minWidth: "" }} field="mobile" header="Mobile" />
                    <Column style={{ minWidth: "" }} body={userTypeTemp} header="Type" />
                    <Column style={{ maxWidth: "5rem" }} body={genderTemp} header="Gender" />
                    <Column style={{ minWidth: "" }} body={signupAsTemp} header="Signup As" />
                    <Column style={{ minWidth: "" }} field="loginDevice" header="Log In Device" />
                    <Column style={{ minWidth: "" }} field="signupDevice" header="Signup Device" />
                    <Column style={{ minWidth: "8rem" }} body={createdAtTemplate} header="Created At" />
                    <Column style={{ minWidth: "8rem" }} body={updatedAtTemplate} header="Updated At" />
                    {/*<Column filter body={statusBodyDriver} header="Status" /> */}
                    <Column body={statusBody} header="Status" />
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div>
        </>
    );
}
